import { TopBar, BottomBar } from "./App";

export const Writings = () => {
  return (
    <>
      <div class="scanlines"></div>
      <div className="max-w-2xl mx-auto p-8 min-h-[95vh]">
        <div className="flex flex-col gap-y-12">
          <TopBar />
          <a
            className="hover:underline"
            href="https://medium.com/@acharbohno/a-digital-nomad-survival-guide-dos-and-donts-cfc1e072c98c"
          >
            <div className="text-2xl font-bold">
              A Digital Nomad Survival Guide: Dos and Don’ts
            </div>

            <div className="text-gray-300">August 23, 2023</div>
            <div className="text-gray-300">
              My learnings from traveling the world for over a year.
            </div>
          </a>
          <a
            className="hover:underline"
            href="https://medium.com/@acharbohno/exploring-portugals-charms-in-2023-28d528e2135e"
          >
            <div className="text-2xl font-bold">
              Exploring Portugal’s Charms in 2023
            </div>

            <div className="text-gray-300">August 13, 2023</div>
            <div className="text-gray-300">
              My experience living in Portugal for 3 months
            </div>
          </a>
          <a
            className="hover:underline"
            href="https://medium.com/@acharbohno/unveiling-the-bittersweet-truths-of-bali-a-digital-nomads-journey-in-2023-f73954313987"
          >
            <div className="text-2xl font-bold">
              Unveiling the Bittersweet Truths of Bali
            </div>

            <div className="text-gray-300">August 2, 2023</div>
            <div className="text-gray-300">
              My experience living in Bali for 4 months
            </div>
          </a>
          <a
            className="hover:underline"
            href="https://medium.com/@acharbohno/langchain-tutorial-and-creating-your-own-ai-tax-pro-21fe137a36b9"
          >
            <div className="text-2xl font-bold">
              Chat with Your PDFs: Langchain AI Unleashed!
            </div>

            <div className="text-gray-300">June 8, 2023</div>
            <div className="text-gray-300">
              How to use Langchain to speak to your PDFs
            </div>
          </a>
        </div>
      </div>
      <BottomBar />
    </>
  );
};
